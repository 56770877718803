import { Elm } from "./Main.elm";

const windowLoaded = new Promise((resolve) => {
  window.addEventListener("load", resolve);
});

// clean the DOM to fix broken links
// https://github.com/elm/browser/issues/105
document.body.childNodes.forEach((node) => {
  node.remove();
});

const app = Elm.Main.init();

app.ports.highlight.subscribe(() => {
  import("./hljs").then((hljs) => {
    requestAnimationFrame(() => {
      document.querySelectorAll(".markdown > pre > code").forEach((element) => {
        hljs.default.highlightBlock(element);
      });
    });
  });
});

// const handleFragmentJump = () => {
// const hash = window.location.hash.slice(1);
// const el = document.getElementById(hash);
// if (el) {
// const header = document.querySelector("header");
// const headerHeight = header.offsetHeight;
// const offset = el.offsetTop - headerHeight;
// console.log({ el, offset, headerHeight });
// window.scrollTo(0, offset);
// }
// };

// tell prerenderer that the page is ready
app.ports.pageRendered.subscribe(() => {
  requestAnimationFrame(() => {
    windowLoaded.then(() => {
      document.dispatchEvent(new Event("page-rendered"));
      // handleFragmentJump();
    });
  });
});

// register SW
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("./sw.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
